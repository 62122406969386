<div class="container">
    <div class="section-title">
        <h2>Planos</h2>
        <div class="bar"></div>
        <p>
            Aqui você encontra as opções de planos perfeitos para alcançar seus
            objetivos de forma personalizada e eficiente. Escolha o que melhor
            se adapta ao seu estilo de vida e comece sua transformação hoje
            mesmo!
        </p>
    </div>

    <div class="tab pricing-list-tab">
        <ul class="tabs">
            <li><a href="#">Consultoria de treino</a></li>
            <li><a href="#">Acompanhamento nutricional</a></li>
            <li><a href="#">Pacote Personal + Nutri</a></li>
        </ul>

        <div class="tab_content">
            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano trimestral</h3>
                            </div>

                            <div class="price">
                                <p><span>2</span> protocolos de treino</p>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Reavaliação:
                                    Após 45 dias, o plano será ajustado conforme
                                    evolução e feedback
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Adaptação ao
                                    local: Planos personalizados para academia,
                                    casa ou ao ar livre.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Explicação
                                    clara: Cada exercício explicado com vídeos
                                    ou descrições detalhadas.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Suporte
                                    contínuo: Atendimento direto para dúvidas e
                                    adaptações no treino durante todo o
                                    período..
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Saiba mais <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano semestral</h3>
                            </div>

                            <div class="price">
                                <p><span>4</span> protocolos de treino</p>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Reavaliação: A
                                    cada 45 dias, o plano será ajustado conforme
                                    evolução e feedback
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Adaptação ao
                                    local: Planos personalizados para academia,
                                    casa ou ao ar livre.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Explicação
                                    clara: Cada exercício explicado com vídeos
                                    ou descrições detalhadas.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Suporte
                                    contínuo: Atendimento direto para dúvidas e
                                    adaptações no treino durante todo o período.
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Saiba mais <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano anual</h3>
                            </div>

                            <div class="price">
                                <p><span>8</span> protocolos de treino</p>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Reavaliação: A
                                    cada 45 dias, o plano será ajustado conforme
                                    evolução e feedback
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Adaptação ao
                                    local: Planos personalizados para academia,
                                    casa ou ao ar livre.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Explicação
                                    clara: Cada exercício explicado com vídeos
                                    ou descrições detalhadas.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Suporte
                                    contínuo: Atendimento direto para dúvidas e
                                    adaptações no treino durante todo o período.
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Saiba mais <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Presencial</h3>
                            </div>

                            <div class="price">
                                <p class="title">
                                    Atendimento exclusivo em consultório
                                    (Ipanema - RJ)
                                </p>
                            </div>
                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Experiência
                                    completa e exclusiva em consultório.
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Avaliação
                                    detalhada por bioimpedância e adipômetro
                                    para análise precisa da sua composição
                                    corporal
                                </li>
                                <li>
                                    <i class="fas fa-check"></i>
                                    Planejamento alimentar 100% personalizado
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Orientações
                                    práticas
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Suporte
                                    contínuo para maximizar seus resultados
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Saiba mais <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano semestral</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Lifetime free
                                    support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Full access
                                </li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> App design</li>
                                <li>
                                    <i class="fa fa-times"></i> Web developement
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Purchase <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano anual</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Lifetime free
                                    support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Full access
                                </li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> App design</li>
                                <li>
                                    <i class="fas fa-check"></i> Web
                                    developement
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Purchase <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano trimestral</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>33 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Lifetime free
                                    support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Full access
                                </li>
                                <li><i class="fa fa-times"></i> Web design</li>
                                <li><i class="fa fa-times"></i> App design</li>
                                <li>
                                    <i class="fa fa-times"></i> Web developement
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Purchase <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano semestral</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Lifetime free
                                    support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Full access
                                </li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fa fa-times"></i> App design</li>
                                <li>
                                    <i class="fa fa-times"></i> Web developement
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Purchase <span></span
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Plano anual</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li>
                                    <i class="fas fa-check"></i> Extra features
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Lifetime free
                                    support
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Upgrate options
                                </li>
                                <li>
                                    <i class="fas fa-check"></i> Full access
                                </li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> App design</li>
                                <li>
                                    <i class="fa fa-times"></i> Web developement
                                </li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn"
                                    >Purchase <span></span
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
