<app-navbar></app-navbar>
<!-- <div  class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <h1>Consultoria de treinos individualizados</h1>
                    <p></p>
                    <div style="margin-top: 30px;">
                        <button
                        type="submit"
                        class="default-btn"
                        style="border-radius: 20px; font-weight: 600; box-shadow: none;"
                    >
                        
                        Quero contratar
                    </button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->
<div class="video-area">
    <app-how-it-works ></app-how-it-works>
</div>

<section class="overview-area ptb-100">
    <app-overview [title]="title" [desc]="desc"></app-overview>
</section>


<!-- <section id="features" class="features-area pt-100">
    <app-features></app-features>
</section> -->

<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>
</section>