<nav class="navbar navbar-expand-xl navbar-light bg-light" style="border-bottom: 1px solid #eee;">
    <div class="container-fluid">
        <div class="logo">
            <img src="assets/img/logo_menu.png" alt="Logo">
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a href="#home" class="nav-link">Home</a></li>
                <li class="nav-item"><a href="#about" class="nav-link">Sobre mim</a></li>
                <li class="nav-item"><a href="#features" class="nav-link">Minha metodologia</a></li>
                <li class="nav-item"><a href="#depoimentos" class="nav-link">Depoimento</a></li>
                <li class="nav-item"><a href="#planos" class="nav-link">Planos</a></li>
                <li class="nav-item"><a href="#faq" class="nav-link">FAQ</a></li>
                <li class="nav-item"><a href="#contato" class="nav-link">Contato</a></li>
            </ul>
        </div>
    </div>
</nav>