<div class="container">
    <div class="section-title">
        <h2>Fale comigo 
            <a
            href="https://www.instagram.com/jufinizio.personalnutri?igsh=MW0ycG44aDE2c2FpdA=="
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src="assets/img/Instagram_icon.png"
                alt="Instagram"
                style="width: 50px; height: 50px"
            />
        </a>
        </h2>
        <div class="bar"></div>
        <p>
            Está pronta(o) para transformar sua saúde, alcançar seus objetivos e
            conquistar a melhor versão de si mesma(o)? Eu estou aqui para te
            ajudar nessa jornada! Entre em contato comigo agora mesmo
            preenchendo o formulário abaixo ou enviando uma mensagem no meu
            Instagram. Seja para esclarecer dúvidas, agendar uma consulta ou
            conhecer os meus planos de consultoria online e presencial, juntos
            vamos criar uma estratégia personalizada para você. Não deixe para
            amanhã o que você pode começar hoje – o primeiro passo está a um
            clique de distância!
        </p>
        
    </div>

    <div class="row align-items-center">
        <div class="col-lg-12">
            <div class="contact-form">
                <form
                    [formGroup]="contactForm"
                    (ngSubmit)="sendToWhatsApp()"
                    id="contactForm"
                >
                    <div class="row">
                        <!-- Campo: Nome -->
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    id="name"
                                    formControlName="name"
                                    class="form-control"
                                    placeholder="Seu nome"
                                />
                                <div
                                    *ngIf="
                                        contactForm.get('name')?.touched &&
                                        contactForm.get('name')?.invalid
                                    "
                                    class="error"
                                >
                                    <small
                                        *ngIf="
                                            contactForm.get('name')?.errors
                                                ?.required
                                        "
                                    >
                                        O nome é obrigatório.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- Campo: E-mail -->
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    formControlName="email"
                                    class="form-control"
                                    placeholder="Seu e-mail"
                                />
                                <div
                                    *ngIf="
                                        contactForm.get('email')?.touched &&
                                        contactForm.get('email')?.invalid
                                    "
                                    class="error"
                                >
                                    <small
                                        *ngIf="
                                            contactForm.get('email')?.errors
                                                ?.required
                                        "
                                    >
                                        O e-mail é obrigatório.
                                    </small>
                                    <small
                                        *ngIf="
                                            contactForm.get('email')?.errors
                                                ?.email
                                        "
                                    >
                                        Digite um e-mail válido.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- Campo: Telefone -->
                        <div class="col-lg-4 col-md-6">
                            <div class="form-group">
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    formControlName="phoneNumber"
                                    class="form-control"
                                    placeholder="Seu telefone (com DDD)"
                                />
                                <div
                                    *ngIf="
                                        contactForm.get('phoneNumber')
                                            ?.touched &&
                                        contactForm.get('phoneNumber')?.invalid
                                    "
                                    class="error"
                                >
                                    <small
                                        *ngIf="
                                            contactForm.get('phoneNumber')
                                                ?.errors?.required
                                        "
                                    >
                                        O telefone é obrigatório.
                                    </small>
                                    <small
                                        *ngIf="
                                            contactForm.get('phoneNumber')
                                                ?.errors?.pattern
                                        "
                                    >
                                        Digite um telefone válido (ex.:
                                        31999999999).
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- Campo: Mensagem -->
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea
                                    id="message"
                                    formControlName="message"
                                    class="form-control"
                                    cols="30"
                                    rows="6"
                                    placeholder="Sua mensagem"
                                ></textarea>
                                <div
                                    *ngIf="
                                        contactForm.get('message')?.touched &&
                                        contactForm.get('message')?.invalid
                                    "
                                    class="error"
                                >
                                    <small
                                        *ngIf="
                                            contactForm.get('message')?.errors
                                                ?.required
                                        "
                                    >
                                        A mensagem é obrigatória.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- Botão: Enviar -->
                        <div class="col-lg-12 col-md-12 btn-group">
                            <button
                                type="submit"
                                class="default-btn"
                                [disabled]="contactForm.invalid"
                            >
                                Enviar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
