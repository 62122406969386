<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa-solid fa-scale-balanced"></i>
                </div>
                <h3>Equilibrio</h3>
                <p>
                    Alcançamos o equilíbrio ideal entre saúde, bem-estar e vida
                    social, sem dietas restritivas ou treinos
                </p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa-solid fa-dove"></i>
                </div>
                <h3><span>Liberdade</span></h3>
                <p>
                    Criamos planos flexíveis, que se adaptam às suas
                    preferências e rotina.
                </p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa-solid fa-award"></i>
                </div>
                <h3><span>Educação</span></h3>
                <p>Aprenda a fazer escolhas alimentares conscientes e entender os exercícios, promovendo sua autonomia.</p>
            </div>
        </div>

        <div class="col-lg-3 col-sm-6">
            <div class="single-fun-fact">
                <div class="icon">
                    <i class="fa-solid fa-heart-pulse"></i>
                </div>
                <h3><span>Vida</span></h3>
                <p>
                    Transformamos sua rotina para melhorar a qualidade de vida,
                    unindo saúde física, mental e bem-estar a longo prazo
                </p>
            </div>
        </div>
    </div>
</div>
