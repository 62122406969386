<div class="container">
    <div class="section-title">
        <h2>Método Elev</h2>
        <div class="bar"></div>

        <p style="font-family: system-ui; line-height: 2rem">
            O Método <span style="font-weight: 600">ELEV</span> é baseado em
            quatro pilares principais:
        </p>
    </div>
    <section class="fun-facts-area">
        <app-funfacts></app-funfacts>
    </section>
    <div class="row mt-70">
        <div class="section-title-2">
            <h2>O que você vai ter</h2>
            <div class="bar"></div>
        </div>
        <div class="col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa-solid fa-bullseye"></i>
                </div>
                <h3>Protocolo personalizado e didático</h3>
                <p style="font-family: system-ui; line-height: 2rem">
                    Em cada consulta, criaremos juntos um plano alimentar e de
                    treino totalmente personalizado, adaptado aos seus
                    objetivos, rotina e preferências. Vamos equilibrar sua
                    alimentação com liberdade e consciência, garantindo uma
                    jornada saudável e prática.
                </p>
            </div>
        </div>

        <div class="col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa-solid fa-weight-scale"></i>
                </div>
                <h3>Avaliação nutricional completa</h3>
                <p style="font-family: system-ui; line-height: 2rem">
                    Nas consultas presenciais, realizamos a aferição do peso,
                    uso do adipometro e bioimpedância para acompanhar seu
                    progresso. No atendimento online, o acompanhamento continua
                    completo! Avaliaremos seus hábitos, sinais e sintomas, e
                    você receberá orientações claras para monitorar o progresso
                    em casa.
                </p>
            </div>
        </div>

        <div class="col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa-solid fa-comment"></i>
                </div>
                <h3>Follow up</h3>
                <p style="font-family: system-ui; line-height: 2rem">
                    Entrarei em contato via whatsapp para saber como anda o
                    processo e como posso te ajudar ainda mais, caso esteja
                    encontrando alguma dificuldade
                </p>
            </div>
        </div>

        <div class="col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa-solid fa-bowl-food"></i>
                </div>
                <h3>Suplementação</h3>
                <p style="font-family: system-ui; line-height: 2rem">
                    Junto com o seu plano alimentar, se necessário, será
                    prescrita uma suplementação para prevenção e tratamento de
                    doenças ou otimização dos resultados.
                </p>
            </div>
        </div>
    </div>
</div>
