<div class="container">
    <div class="section-title">
        <h2>Perguntas frequentes</h2>
        <div class="bar"></div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="faq-image">
                <img src="assets/img/ju-faq.png" alt="image" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 pb-70">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                O acompanhamento online é eficaz?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseOne"
                            class="collapse show"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Sim! Durante as consultas online, você terá
                                    suporte completo e orientações detalhadas
                                    para monitorar seu progresso de forma
                                    eficiente.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                Qual a diferença da consulta online e da
                                consultoria na parte nutricional?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseTwo"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Na consulta online, o atendimento é feito
                                    por videochamada, o que permite uma
                                    interação direta. Você recebe o plano
                                    alimentar personalizado com base nessa
                                    conversa. Já a consultoria é feita por meio
                                    de um formulário detalhado que você
                                    preenche, onde coloco todas as informações
                                    necessárias para montar seu plano alimentar.
                                    Esse formato é ideal para quem prefere um
                                    acompanhamento mais flexível e menos
                                    intensivo, mas ainda quer resultados com um
                                    plano personalizado.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                Por que não há plano mensal?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseThree"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Opto por não oferecer planos mensais porque
                                    o acompanhamento nutricional e de treino
                                    requer um período mínimo para que as
                                    mudanças comecem a trazer resultados
                                    visíveis e consistentes. Um acompanhamento
                                    mais longo, como o trimestral, semestral ou
                                    anual, permite que haja tempo suficiente
                                    para ajustar o plano, acompanhar o progresso
                                    e garantir que você alcance seus objetivos
                                    de maneira sustentável. Planos mais curtos
                                    podem não proporcionar esse tempo necessário
                                    para ajustes e evolução e não trazer adesão.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFour">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                Posso fazer apenas o acompanhamento de treino ou
                                nutrição?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseFour"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Sim, você pode optar por apenas um dos
                                    serviços – o acompanhamento de treino ou o
                                    acompanhamento nutricional. No entanto,
                                    muitos pacientes preferem o pacote completo,
                                    pois a integração entre nutrição e treino
                                    potencializa os resultados de forma mais
                                    eficiente.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingFive">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                Quais são as formas de pagamento aceitas?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseFive"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Aceito pagamentos via Pix e cartão de
                                    crédito. Para quem preferir parcelar o
                                    valor, os pagamentos com cartão de crédito
                                    podem ser divididos em até 12 vezes, porém,
                                    é importante lembrar que a opção no cartão
                                    inclui uma taxa adicional para cobrir os
                                    custos de operação da administradora.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingSix">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSix"
                                aria-expanded="false"
                                aria-controls="collapseSix"
                            >
                                Como funciona o suporte entre as consultas?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseSix"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Durante o acompanhamento, você terá suporte
                                    contínuo comigo via WhatsApp. Isso significa
                                    que, caso surjam dúvidas sobre o plano
                                    alimentar ou os treinos, você pode entrar em
                                    contato comigo a qualquer momento para obter
                                    orientações e ajustes necessários.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingSeven">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                            >
                                No protocolo de treino, posso escolher treinos
                                em casa ou só em academia?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseSeven"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Sim, os treinos podem ser ajustados de
                                    acordo com suas preferências. Se você
                                    prefere treinos ao ar livre, podemos incluir
                                    exercícios que se adaptem a esse ambiente. O
                                    importante é que o treino seja adequado ao
                                    seu estilo de vida e objetivos.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingEight">
                            <button
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEight"
                                aria-expanded="false"
                                aria-controls="collapseEight"
                            >
                                E depois que acabar o tempo do meu
                                acompanhamento?
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div
                            id="collapseEight"
                            class="collapse"
                            data-bs-parent="#faqAccordion"
                        >
                            <div class="card-body">
                                <p>
                                    Ao final do período do seu acompanhamento,
                                    você não terá mais acesso aos treinos e
                                    dieta no aplicativo. Caso deseje renovar o
                                    plano, você poderá recuperar o acesso
                                    completo aos treinos e ao suporte de
                                    acompanhamento, ajustado para sua nova fase
                                    e metas. A renovação pode ser feita em
                                    qualquer momento, com a opção de escolher
                                    entre os planos trimestral, semestral ou
                                    anual.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
