import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactForm!: FormGroup;
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.contactForm = this.fb.group({
            name: ["", [Validators.required]], // Campo obrigatório
            email: ["", [Validators.required, Validators.email]], // Campo obrigatório e deve ser um e-mail válido
            phoneNumber: [
                "",
                [
                    Validators.required,
                    Validators.pattern(/^\(?\d{2}\)?[\s-]?\d{4,5}[-]?\d{4}$/), // Regex para telefone brasileiro
                ],
            ],
            message: ["", [Validators.required]], // Campo obrigatório
        });
    }

    sendToWhatsApp() {
        // Verifica se o formulário é válido
        if (this.contactForm.invalid) {
            this.contactForm.markAllAsTouched(); // Marca todos os campos como tocados para exibir os erros
            return;
        }

        // Extrai os valores do formulário
        const { name, email, phoneNumber, message } = this.contactForm.value;

        // Formata os dados para enviar para o WhatsApp
        const whatsappNumber = "5521998604979"; // Substitua pelo número desejado
        const text = `
        Nome: ${name}%0A
        E-mail: ${email}%0A
        Telefone: ${phoneNumber}%0A
        Mensagem: ${message}
      `;
        const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
            text
        )}`;

        // Redireciona para o WhatsApp
        window.open(whatsappUrl, "_blank");
    }
}
