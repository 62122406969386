<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-12">
            <div class="overview-content">
                <h3>{{ title }}</h3>
                <div class="row row-list" *ngFor="let item of desc">
                    <div class="col-md-1 flex-center">
                        <i class="fa-solid fa-check"></i>
                    </div>
                    <div class="col-md-3 list-item flex-center">
                        <p>{{ item.title }}</p>
                    </div>
                    <div class="col-md-7 flex-center">
                        <p>{{ item.desc }}</p>
                    </div>
                </div>
                <div class="overview-btn " style="text-align: center;">
                    <a routerLink="/" class="default-btn"
                        >Quero contratar<span></span
                    ></a>
                </div>
            </div>
        </div>

        <!-- <div class="col-lg-3">
            <div class="overview-image">
                <img src="assets/img/overview.png" alt="image" />
            </div>
        </div> -->
    </div>
</div>
