<!-- <app-navbar></app-navbar> -->
<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Transforme sua vida com saúde e bem estar</h1>
                            <p>
                                Descubra como é simples transformar seu corpo e
                                mente com planos personalizados de treino e
                                nutrição. Mais saúde, mais energia, mais você!
                            </p>
                            <div class="banner-holder scroll">
                                <a href="#contato">
                                    <button
                                        type="button"
                                        class="default-btn"
                                        style="
                                            border-radius: 20px;
                                            font-weight: 600;
                                        "
                                    >
                                        <i class="fa-solid fa-arrow-down"></i>
                                        Fale comigo
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img
                                src="assets/img/ju-transparente (cópia).png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1 rotateme">
            <img
                src="assets/img/shape/melancia.png"
                alt="image"
                style="max-width: 45px"
            />
        </div>
        <div class="shape-2">
            <img
                src="assets/img/shape/banana.png"
                style="max-width:  60px"
                alt="image"
            />
        </div>
        <div class="shape-3">
            <img
                src="assets/img/shape/morango.png"
                style="max-width: 60px"
                alt="image"
            />
        </div>
        <div class="shape-4">
            <img
                src="assets/img/shape/uva.png"
                style="max-width: 60px"
                alt="image"
            />
        </div>
        <div class="shape-5">
            <img
                src="assets/img/shape/laranja.png"
                style="max-width: 60px"
                alt="image"
            />
        </div>
        <div class="shape-6">
            <img
                src="assets/img/shape/mamao.png"
                style="max-width: 60px"
                alt="image"
            />
        </div>
    </div>
</div>

<section id="about" class="about-area pb-100 pt-100">
    <app-about></app-about>
</section>

<section id="features" class="features-area pb-70">
    <app-features></app-features>
</section>

<section id="depoimentos">
    <app-app-screenshots></app-app-screenshots>
</section>

<section class="pricing-area pt-100 pb-70" id="planos">
    <planos-adesao></planos-adesao>
</section>

<section id="faq" class="faq-area pt-100">
    <app-faq></app-faq>
</section>

<section id="contato" class="contact-area ptb-100" style="background: #fff">
    <app-contact></app-contact>
</section>
