<div class="container-fluid" style="padding-right: 0; padding-left: 0; margin-top: 5px;">
    <div class="video-content">
        <h3>Como funciona?</h3>
        <a
            href="https://www.youtube.com/watch?v=wKCsprWTN70"
            class="video-btn popup-youtube"
            ><i class="fas fa-play"></i
        ></a>
    </div>
</div>
