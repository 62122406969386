<div class="container">
    <div class="section-title">
        <h2>Planos</h2>
        <div class="bar"></div>
        <p>
            Aqui você encontra as opções de planos perfeitos para alcançar seus
            objetivos de forma personalizada e eficiente. Escolha o que melhor
            se adapta ao seu estilo de vida e comece sua transformação hoje
            mesmo!
        </p>
    </div>
    <div class="plain-container">
        <div class="row">
            <div class="col-md-4">
                <div class="team-box">
                    <img
                        src="assets/img/IMG_3328.png"
                        alt="Image Description"
                    />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="box-content">
                                <h3 class="title">
                                    Consultoria <br />de treino
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box">
                    <img
                        src="assets/img/IMG_3280.png"
                        alt="Image Description"
                    />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="box-content">
                                <h3 class="title">Plano nutricional</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="team-box">
                    <img
                        src="assets/img/IMG_4056.png"
                        alt="Image Description"
                    />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="box-content">
                                <h3 class="title">
                                    Pacote <br />
                                    personal + Nutri
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 scroll" style="text-align: center; margin-top: 50px">
            <a href="#contato">
                <button
                    type="submit"
                    class="default-btn"
                    style="border-radius: 20px; font-weight: 600"
                >
                    <i class="fa-solid fa-arrow-down"></i>
                    Fale comigo
                </button>
            </a>
        </div>
    </div>
</div>
