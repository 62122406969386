<div class="container">
    <div class="section-title">
        <h2>Sobre mim</h2>
        <div class="bar"></div>
        <p><Label> </Label></p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12" style="padding: 0 30px">
            <div class="about-content">
                <div class="about-header">
                    <h3>Juliana Rodrigues Finizio</h3>
                    <a
                        href="https://www.instagram.com/jufinizio.personalnutri?igsh=MW0ycG44aDE2c2FpdA=="
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="assets/img/Instagram_icon.png"
                            alt="Instagram"
                            style="width: 50px; height: 50px"
                        />
                    </a>
                </div>
                <p
                    style="
                        font-family: system-ui;
                        line-height: 2rem;
                        margin-top: 10px;
                    "
                >
                    Olá! Sou Juliana Finizio, nutricionista e personal trainer,
                    e criei o
                    <span style="font-weight: 600">Método ELEV</span> com um
                    propósito: transformar vidas e ajudar você a alcançar seus
                    objetivos. Sei que tomar a decisão de buscar acompanhamento
                    é um passo crucial, e estou aqui para te guiar nesse
                    processo de mudança. O primeiro passo é sempre o mais
                    importante, e ao buscar um acompanhamento profissional, você
                    está investindo em muito mais do que apenas sua saúde. Você
                    está investindo nos seus objetivos – seja perder peso,
                    ganhar massa muscular, melhorar o desempenho nos treinos ou
                    até mesmo ajustar a alimentação para maior disposição e
                    bem-estar.
                </p>
                <p style="font-family: system-ui; line-height: 2rem">
                    Através do
                    <span style="font-weight: 600">Método ELEV</span>, meu foco
                    é criar um caminho personalizado, que respeite suas
                    individualidades e leve em conta tanto seus desafios quanto
                    suas metas. Combinando alimentação adequada e treinos
                    estruturados, vou te ajudar a conquistar resultados
                    concretos e duradouros, de forma sustentável e prática.
                    Agora que você já sabe um pouco mais sobre mim e o meu
                    estilo de trabalho, a pergunta que fica é:
                </p>
                <p
                    style="
                        font-family: system-ui;
                        line-height: 2rem;
                        font-weight: 600;
                        color: #0b2c59;
                    "
                >
                    Você está pronto(a) para transformar seus treinos e sua
                    alimentação e, por consequência, sua vida? Vamos juntos?
                </p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image" style="text-align: center">
                <img
                    src="assets/img/IMG_3328.png"
                    style="border-radius: 20px; width: 75%"
                />
            </div>
        </div>
    </div>
</div>
