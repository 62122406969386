import { Component, OnInit } from "@angular/core";

const title = "Consultoria de treinos individualizada";
const desc = [
    {
        title: "Treino 100% Personalizado",
        desc: "Cada sessão e exercício são adaptados às suas necessidades, objetivos e nível de condicionamento físico",
    },
    {
        title: "Treino 100% Personalizado",
        desc: "Cada sessão e exercício são adaptados às suas necessidades, objetivos e nível de condicionamento físico",
    },
    {
        title: "Treino 100% Personalizado",
        desc: "Cada sessão e exercício são adaptados às suas necessidades, objetivos e nível de condicionamento físico",
    },
    {
        title: "Treino 100% Personalizado",
        desc: "Cada sessão e exercício são adaptados às suas necessidades, objetivos e nível de condicionamento físico",
    },
    {
        title: "Treino 100% Personalizado",
        desc: "Cada sessão e exercício são adaptados às suas necessidades, objetivos e nível de condicionamento físico",
    },
];
@Component({
    selector: "app-home-two",
    templateUrl: "./home-two.component.html",
    styleUrls: ["./home-two.component.scss"],
})
export class HomeTwoComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    get title() {
        return title;
    }

    get desc() {
        return desc;
    }
}
