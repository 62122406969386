<section id="screenshots" class="screenshot-area ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Depoimentos</h2>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme" style="padding: 40px 0px 0;">
            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-1.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-2.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-3.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-4.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-5.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-6.png" alt="image">
                </div>
            </div>

            <div class="screenshot-item">
                <div class="image">
                    <img src="assets/img/client/depoimento-7.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>