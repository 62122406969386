import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'planos-adesao',
  standalone: true,
  imports: [],
  templateUrl: './planos-adesao.component.html',
  styleUrl: './planos-adesao.component.scss'
})
export class PlanosAdesaoComponent {

  constructor(private router:Router) {}

  navigateToPage(){
    this.router.navigate(['/consultoria-personal']);
  }
}
