import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';


const routes: Routes = [
    /* { path: '', redirectTo: '/contato', pathMatch: 'full' }, */
    {path: '', component: HomeOneComponent},
    {path: 'consultoria-personal', component: HomeTwoComponent},
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }