<div class="copy-right" style="background-color: #0b2c59">
    <div class="container">
        <div class="copy-right-content">
            <p>Doutora Juliana Copyright ® 2024 - Todos os Direitos Reservados.
            </p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>
